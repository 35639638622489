import { useQuery } from 'react-query';

import { EventController } from 'services/apiClient/interfaces/EventController';

export function useLineup({ id, initialData, enabled = true, preview = false, token = null }) {
  const lineupQuery = ['event-lineup', { id }];
  const {
    data: lineup,
    isLoading,
    isSuccess,
    refetch,
  } = useQuery(lineupQuery, () => EventController.getLineup({ id, preview, token }), {
    initialData: initialData?.eventLineUp,
    staleTime: Infinity,
    enabled,
  });

  return { lineup, refetch, isLoading, isSuccess };
}
